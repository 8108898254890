import { createTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';

const theme = createTheme({
    palette: {
        primary: teal,
        secondary: teal
    }
});

export default theme;